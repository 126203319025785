<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="ID">
        <el-input v-model="formInline.id" placeholder="请输入ID"></el-input>
      </el-form-item>
      <el-form-item label="账号类型">
        <el-select
          v-model="formInline.account_type"
          placeholder="请选择账号类型"
        >
          <el-option label="员工" value="staff"></el-option>
          <el-option label="UP主" value="vlogger"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="登陆账号">
        <el-input
          v-model="formInline.account"
          placeholder="请输入登陆账号"
        ></el-input>
      </el-form-item>
      <el-button @click="search" :loading="loading">查询</el-button>
      <el-button
        v-has="'/api/vlogger/create'"
        type="primary"
        @click="
          addFrom = !addFrom;
          dialogType = 'add';
          data = {};
        "
        >新增</el-button
      >
      <el-button @click="refesh">刷新</el-button>
    </el-form>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
    >
      <el-table-column
        fixed
        prop="id"
        label="ID"
        align="center"
      ></el-table-column>
      <el-table-column
        fixed
        prop="account"
        label="登陆账号"
        align="center"
      ></el-table-column>
      <el-table-column
        fixed
        prop="account_type"
        label="账号类型"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.account_type === "vlogger" ? "UP主" : "员工" }}
        </template>
      </el-table-column>
      <el-table-column
        fixed
        prop="product"
        label="关联产品"
        align="center"
        :formatter="changeData"
      ></el-table-column>
      <el-table-column
        fixed
        prop="last_login_ip"
        label="最后登陆ip"
        align="center"
      ></el-table-column>
      <el-table-column
        fixed
        prop="last_login_time"
        label="最后登陆时间"
        align="center"
      ></el-table-column>
      <el-table-column
        fixed
        prop="upload_privilege"
        label="大文件上传特权"
        align="center"
      >
        <template slot-scope="scope">
          <el-switch
            @change="updateData(scope.row)"
            v-model="scope.row.upload_privilege"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column fixed prop="status" label="状态" align="center">
        <template slot-scope="scope">
          {{ scope.row.status === true ? "正常" : "禁用" }}
        </template>
      </el-table-column>
      <el-table-column
        fixed
        prop="max_publish"
        label="每日上传视频次数"
        align="center"
      ></el-table-column>

      <el-table-column fixed="right" label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button
            @click="edit(scope.row, 'disabled')"
            type="text"
            size="small"
            >查看</el-button
          >
          <el-button
            type="text"
            @click="edit(scope.row)"
            v-has="'/api/vlogger/update'"
            size="small"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <div class="page">
        <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="formInline.page"
          :page-size="formInline.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination>
      </div>
    </div>
    <addForm
      :show="addFrom"
      :dialogType="dialogType"
      :disabled-type="disabled"
      :data="data"
      @clearData="clearData"
    ></addForm>
  </div>
</template>

<script>
import API from "@/api";
import addForm from "./components/addForm.vue";
export default {
  name: "manage",
  components: { addForm },
  data() {
    return {
      addFrom: false,
      tableData: [],
      loading: false,
      disabled: false,
      formInline: {
        account: "",
        account_type: "",
        id: "",
        page: 1,
        size: 10,
      },
      dialogType: "add",
      data: {},
      total: 0,
      isRefesh: true,
    };
  },
  created() {
    let listRule = "/api/vlogger/list";
    let obj = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem("btnPower")))
    );
    if (obj[listRule]) {
      this.isRefesh = true;
      this.getDataList();
    } else {
      this.isRefesh = false;
    }
  },
  methods: {
    refesh() {
      this.isRefesh ? this.getDataList() : "";
    },
    async getDataList() {
      this.loading = true;
      const { data, code } = await API.getUpListData(this.formInline);
      if (!code) {
        this.tableData = data.data;
        this.total = data.pagination.count;
        this.tableData.forEach((item) => {
          if (item.last_login_time) {
            const timestampCreate = item.last_login_time * 1000;
            const dateCr = new Date(timestampCreate);
            item.last_login_time = dateCr.toLocaleString();
          } else {
            item.last_login_time = "无";
          }
        });
        this.loading = false;
      } else {
        this.tableData = [];
        this.loading = false;
      }
    },
    edit(data, type) {
      this.addFrom = true;
      type ? (this.dialogType = "look") : (this.dialogType = "edit");
      this.data = JSON.parse(JSON.stringify(data));
      this.disabled = !!type;
    },
    search() {
      this.formInline.page = 1;
      this.getDataList();
    },
    closeAdd() {
      this.addFrom = false;
      this.getDataList();
    },
    async updateData(row) {
      await API.updateUser(row).then(() => {
        this.getDataList();
      });
    },
    clearData() {
      this.addFrom = false;
    },
    // 分页条数改变回调
    changeSize(size) {
      this.formInline.size = size;
      this.getDataList();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.formInline.page = number;
      this.getDataList();
    },
    changeData(row) {
      let list = [];
      if (row.product) {
        list = row.product.map((v) => v.product_name);
      }
      return list.join(",");
    },
  },
};
</script>

<style scoped>
.action-bar {
  display: flex;
}
</style>
